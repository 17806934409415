export const kebabize = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase())

export const kebabizeStep = (step) => {
  const key = Object.keys(step)[0]
  return kebabize(key)
}

export const getKickoutUrlFromMeta = ({ state }) => {
  const { kickout } = state.value
  return {
    url: `/${state.meta[kickout]?.slug}`,
  }
}

export const formatPrice = ({ price, locale, currency }) => {
  const options = {
    currency,
    style: 'currency',
  }

  if (price) {
    return new Intl.NumberFormat(locale, options)
      .format(price / 100)
      .replace('.00', '')
  }
  return new Intl.NumberFormat(locale, options)
    .format(0)
    .replace('.00', '')
}

export const formatDate = ({ dateString, locale }) => {
  const date = new Date(dateString)
  return date.toLocaleDateString(locale, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })
}

export const getPaymentMethod = (supportedOptions) => (
  Object.entries(supportedOptions).reduce((previous, [key, value]) => {
    if (value) return key
    return previous
  }, '').toLowerCase()
)

export const isRedundantProxy = (question, ctx) => (
  !!question.dependsOn && ctx[question.dependsOn.id] !== question.dependsOnValue
)

export const getStaleProxyQuestionIds = (questions = [], ctx = {}) => (
  (questions || [])
    .filter((q) => isRedundantProxy(q, ctx) && (q.id in ctx))
    .map((q) => q.id)
)

export const arrayIntersects = (array1, array2) => array1.some((value) => array2.includes(value))

export const compact = (arr) => arr.filter((v) => v !== null && v !== undefined)

export const toSmartLowerCase = (input) => input.replace(/(\w*[a-z0-9_]+\w*)/g, (a) => a.toLowerCase())
