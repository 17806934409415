import React from 'react'
import NextImage from 'next/image'
import NextFutureImage from 'next/future/image'
import { buildContentfulImageURL } from '@sh24/contentful-service/utils'
import resizeRelative from './resize-relative'

const Image = ({
  image,
  maxWidth,
  maxHeight,
  quality = 75,
  layout = 'responsive',
  priority = false,
  future = false,
}) => {
  if (!image) return null
  const { srcWidth, srcHeight } = image
  const { width, height } = resizeRelative({
    srcWidth,
    srcHeight,
    maxHeight,
    maxWidth,
  })

  const Component = future ? NextFutureImage : NextImage

  return (
    <Component
      loader={buildContentfulImageURL}
      src={image.src}
      width={width}
      height={height}
      priority={priority}
      quality={quality}
      layout={layout}
      alt={image.description || ''}
    />
  )
}

export default Image
