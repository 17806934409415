import {
  Dispatch, SetStateAction, useMemo, useState,
} from 'react'
import { ResultsData } from '@sh24/admin-api-js'
import { ErrorData } from '../../types/api'
import fetchResults from '../../services/accounts/fetch-results'
import getEmptySetter from '../../utils/get-empty-setter'

export type ResultsContextType = {
  results: ResultsData | null,
  setResults: Dispatch<SetStateAction<ResultsData | null>>,
  errors: ErrorData | null,
  setErrors: Dispatch<SetStateAction<ErrorData | null>>,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  refreshResults?: () => Promise<void>,
}

export const defaultResultsContext = {
  results: null,
  setResults: getEmptySetter(),
  errors: null,
  setErrors: getEmptySetter(),
  isLoading: true,
  setIsLoading: getEmptySetter(),
}

export const createResultsContext = () => {
  const [results, setResults] = useState<ResultsData | null>(null)
  const [errors, setErrors] = useState<ErrorData | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const refreshResults = useMemo(() => async () => {
    setIsLoading(true)
    const fetchedResults = await fetchResults(false)
    if (Object.hasOwn(fetchedResults, 'errors')) {
      setErrors(fetchedResults as ErrorData)
    } else {
      setResults(fetchedResults as ResultsData)
      setErrors(null)
    }
    setIsLoading(false)
  }, [setIsLoading, setErrors, setResults])

  return useMemo(() => ({
    results,
    setResults,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    refreshResults,
  }), [
    results,
    setResults,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    refreshResults,
  ])
}
